import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { Link } from 'gatsby'

import Typography from '@eig-builder/core-ui/Typography'

import { withModifiers } from '../../helpers/withModifiers'

const counterContainer = css`
  ${props =>
    props.counterSection &&
    `
      counter-reset: ${props.counterSection};
    `}
`

const counter = css`
  ${({ counter: pCounter, counterType = 'decimal' }) =>
    pCounter &&
    `
      &::before {
        counter-increment: ${pCounter};
        content: counter(${pCounter}, ${counterType}) ". ";
      }
    `}
`

const Arbitration = styled.section.attrs(props => ({ className: withModifiers(props) }))`
  ${space}
  ${counterContainer}

  &._borderBottom {
    padding-top: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.black.lighter};
  }
`

Arbitration.subSection = styled.div`
  ${space}
  ${counterContainer}
`

Arbitration.Header = styled(Typography).attrs(() => ({ variant: 'h1' }))({}, space)

Arbitration.SubHeader = styled(Typography).attrs(() => ({ variant: 'h2' }))`
  ${counter}
  ${space}
`

Arbitration.Text = styled(Typography).attrs(({ variant }) => ({ variant: variant || 'body1' }))(
  {},
  counter,
  space
)

Arbitration.basicText = styled.span.attrs(props => ({ className: withModifiers(props) }))`
  &._bold {
    font-weight: bold;
    line-height: 1.42857;
  }
`

Arbitration.uList = styled.ul.attrs(({ listType = 'upper-alpha' }) => ({ listType }))`
  list-style: ${({ listType }) => listType}};
`

Arbitration.listItem = styled.li`
  padding: 8px 0;
`

Arbitration.Link = styled(Link)``

export default Arbitration
