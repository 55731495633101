import React from 'react'

import Arbitration from './arbitration-agreement.styles'

const ArbitrationAgreement = () => (
  <Arbitration>
    <Arbitration.subSection py={3}>
      <Arbitration.Header pb={2}>Sitebuilder Arbitration Agreement</Arbitration.Header>
      <Arbitration.Text component="span">
        <Arbitration.basicText _bold>
          If you signed up for or purchased Services on or after July 1, 2017 , this Arbitration
          Agreement applies to you.
        </Arbitration.basicText>
        <br />
        <br />
        This Arbitration Agreement includes an agreement that all claims will be brought only in an
        individual capacity (and not as a class action or other representative proceeding). Please
        read it carefully. You may opt out of this agreement by following the opt out procedure
        described in Section 11. Unless otherwise stated, defined terms in this Arbitration
        Agreement have the meanings provided to them in the Sitebuilder Terms of Service.
        Sitebuilder may modify this Arbitration Agreement at any time with appropriate notice.
      </Arbitration.Text>
    </Arbitration.subSection>
    <Arbitration.subSection py={4}>
      <Arbitration.Header pb={2}>Sitebuilder Arbitration Agreement</Arbitration.Header>
      <Arbitration.subSection counterSection="arbitration">
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Informal Process First. `}</Arbitration.basicText>
          Any dispute or claim that remains unresolved after the informal dispute resolution
          described in Section 1 except for disputes relating to the infringement of our
          intellectual property rights or the access or use of the Service in violation of these
          terms (a “Claim”) or Claims seeking more than $10,000 in damages, will be resolved by
          binding arbitration, rather than in court, provided that you may assert Claims in small
          claims court located in Suffolk County, Massachusetts if your Claims qualify.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Binding Arbitration. `}</Arbitration.basicText>
          You agree that in the event of any dispute between you and Sitebuilder, you will first
          contact us and make a good faith sustained effort to resolve the dispute before resorting
          to arbitration under these terms.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`No Judge or Jury. `}</Arbitration.basicText>
          There is no judge or jury in arbitration, and court review of an arbitration award is
          limited. However, an arbitrator can award on an individual basis the same damages and
          relief as a court (including injunctive and declaratory relief or statutory damages), and
          must follow these terms as a court would.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Arbitrator and Rules. `}</Arbitration.basicText>
          The arbitration will be conducted before a neutral single arbitrator, whose decision will
          be final and binding, and the arbitral proceedings will be governed by the AAA Commercial
          Arbitration Rules, Consumer Due Process Protocol, and Supplementary Procedures for
          Resolution of Consumer. Related Disputes. These rules can be found on the AAA website at
          www.adr.org.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Starting an Arbitration. `}</Arbitration.basicText>A party
          who intends to seek arbitration must first send written notice to Sitebuilder’s Legal
          Department of its intent to arbitrate ("Notice"). The Notice to Sitebuilder should be sent
          by any of the following means: (i) electronic mail to{' '}
          <a href="mailto:legal@Sitebuilder.com">legal@Sitebuilder.com</a>; or (ii) sending the
          Notice by U.S. Postal Service certified mail to Sitebuilder, Attn: Legal Department, 5335 Gate Pkwy,
          Jacksonville, FL 32256. The Notice must (x) describe the nature
          and basis of the claim or dispute; and (y) set forth the specific relief sought. If we do
          not reach an agreement to resolve the claim within thirty (30) days after the Notice is
          received, you or Sitebuilder may commence an arbitration proceeding.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Format of Proceedings. `}</Arbitration.basicText>
          The arbitration will be conducted, at the option of the party seeking relief, by
          telephone, online, or based solely on written submissions.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Fees. `}</Arbitration.basicText>
          There is no judge or jury in arbitration, and court review of an arbitration award is
          limited. However, an arbitrator can award on an individual basis the same damages and
          relief as a court (including injunctive and declaratory relief or statutory damages), and
          must follow these terms as a court would.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText
            _bold
          >{`Individual Basis; Jury Trial Waiver. `}</Arbitration.basicText>
          To the fullest extent permitted by applicable law, you and Sitebuilder each agree that any
          proceeding to resolve a Claim will be conducted only in the respective party’s individual
          capacity and not as a plaintiff or class member in any purported class, consolidated,
          multiple plaintiff or representative action (“Class Action”). If for any reason a Claim
          proceeds in court rather than in arbitration, you and Sitebuilder each waive any right to
          a jury trial. You and Sitebuilder expressly waive any ability to maintain any Class Action
          in any forum. If the Claim is subject to arbitration, the arbitrator will not have
          authority to combine or aggregate similar claims or conduct any Class Action nor make an
          award to any person or entity not a party to the arbitration. Any claim that all or part
          of this Class Action Waiver is unenforceable, unconscionable, void, or voidable may be
          determined only by a court of competent jurisdiction and not by an arbitrator. Further,
          you agree that the arbitrator may not consolidate proceedings or more than one person's
          claims, and may not otherwise preside over any form of a representative or class
          proceeding, and that if this specific proviso is found to be unenforceable, then the
          entirety of this arbitration clause shall be null and void. The arbitrator may award
          injunctive relief only in favor of the individual party seeking relief and only to the
          extent necessary to provide relief warranted by that party's individual claim.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Enforcement. `}</Arbitration.basicText>
          Any judgment on the award rendered by the arbitrator may be entered in any court of
          competent jurisdiction. The United Nations Conventions on Contracts for the International
          Sale of Goods will have no applicability.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Invalidity. `}</Arbitration.basicText>
          If a court of competent jurisdiction finds the foregoing arbitration provisions invalid or
          inapplicable, you and Sitebuilder each agree to the exclusive jurisdiction of the Federal
          and State courts located in Boston, Massachusetts, and you and Sitebuilder each agree to
          submit to the exercise of personal jurisdiction of such courts for the purposes of
          litigating any applicable dispute or claim.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Opting Out. `}</Arbitration.basicText>
          If you do not want to arbitrate disputes with us and you are an individual, you may opt
          out of this arbitration agreement by sending an email to{' '}
          <a href="mailto:legal@Sitebuilder.com">legal@Sitebuilder.com</a> within thirty (30) days
          of the first of the date you access or use the Service.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`Confidentiality. `}</Arbitration.basicText>
          There is no judge or jury in arbitration, and court review of an arbitration award is
          limited. However, an arbitrator can award on an individual basis the same damages and
          relief as a court (including injunctive and declaratory relief or statutory damages), and
          must follow these terms as a court would.
        </Arbitration.Text>
        <Arbitration.Text counter="arbitration" py={3}>
          <Arbitration.basicText _bold>{`No Judge or Jury. `}</Arbitration.basicText>
          The parties shall maintain the confidential nature of the arbitration proceeding and any
          award, including the hearing, except as may be necessary to prepare for or conduct the
          arbitration hearing on the merits, or except as may be necessary in connection with a
          court application for a preliminary remedy, a judicial challenge to an award or its
          enforcement, or unless otherwise required by law or judicial decision.
        </Arbitration.Text>
      </Arbitration.subSection>
      <Arbitration.Text variant="body2">Last updated: March 2, 2022</Arbitration.Text>
    </Arbitration.subSection>
  </Arbitration>
)

export default ArbitrationAgreement
