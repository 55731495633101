import React from 'react'

import Layout from './../../components/layout'
import TermsLayout from '../../components/layout/terms-layout'
import ArbitrationAgreement from '../../components/arbitration-agreement'
import SEO from '../../components/seo'

const AcceptableUsePage = () => (
  <Layout isWhiteHeader paddingTop>
    <SEO
      title='Arbitration Agreement'
      keywords={[
        `web hosting`,
        `domain names`,
        `web site`,
        `search engine optimization`,
        `hosting`,
        `servers`
      ]}
      pageType={'company_info'}
      flowType={'none'}
    />
    <TermsLayout>
      <ArbitrationAgreement />
    </TermsLayout>
  </Layout>
)

export default AcceptableUsePage
